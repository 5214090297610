import React from "react";
import '../styles/long1.css';
import '../styles/driverprofile.css';
import Layout from "../components/Layout";
import SEO from "../components/SEO";


const IndexPage = () => (
    <div class="grid-container">
    <div className="header">
        <div className="header1"> 
            <div className="logo-div">
                <a aria-current="page" className="logo" href="/"><div className="driverprofile-logo"></div></a>
            </div>
        </div>
        <a href="https://www.texpresslanes.com/" target="_blank"><div className="header2">What are TEXpress Lanes</div></a>
        <a href="https://www.texpresslanes.com/maps/texpress-lanes-map" target="_blank"><div className="header3">Where are TEXpress Lanes</div></a>
        <a href="https://www.texpresslanes.com/navigating-texpress-lanes/how-to-use" target="_blank"><div className="header4">How to Use TEXpress Lanes</div></a>
   </div>
    <div class="left_space"></div>
    <div className="short1-main">
        <div className="driverprofile-main1 driverprofile-bg">
            <h1 className="main-body-header">Leave Traffic in the Dust with TEXpress Lanes</h1>
            <p className="main-body-text">Dallas/Fort Worth is home to 25% of the most congested highways in Texas. Leave the traffic behind and say hello to the open road on the TEXpress Lanes.</p>
            <p className="main-body-text">TEXpress Lanes partner alongside state and local officials to offer drivers a choice for a faster, safer and more reliable commute.</p>
            <p className="main-body-text main-body-text-space">Ready to hit the road?</p>
            <div className="driverprofile-form-section"><div id='lightbox-inline-form-ef97e36d-491f-4e28-948e-5f5d34b52e2c' className="register-formBorder"></div></div>
        </div>
        <div className="driverprofile-main2">
            <div className="driverprofile-main2-left">
                <div className="driverprofile-img"></div>
            </div>
            <div className="driverprofile-main2-right">
                <p className="driverprofile-main2-right-header">What are<br />TEXpress Lanes?</p>
                <p className="driverprofile-main2-right-text">TEXpress Lanes are unique toll lanes built within existing highways to accommodate more traffic volume and reduce congestion. Unlike traditional toll roads, the price changes based on real-time traffic conditions in order to keep everyone moving at least 50 MPH.</p>
            </div>
        </div>
        <div className="driverprofile-main3">
            <p className="bot-main-header">Where Can I Use TEXpress Lanes?</p>
            <p className="bot-main-text">TEXpress Lanes are available on eight major DFW corridors:</p>
            <p className="bot-main-address">LBJ Express (I-635)   |   North Tarrant Express   |   I-820, SH 183 and SH 121   |   I-35W<br />I-30/Tom Landry Freeway (west segment opens in 2020)   |   I-35E   |   SH 114   |   SH 183   |   Loop 12</p>
        </div>
    </div>
    <div class="right_space"></div>
    <div class="footer">
            <div class="register-footer1"><a href="https://www.facebook.com/TEXpressLanes" target="_blank"><img class="fb-icon"
  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> | <a href="https://twitter.com/texpresslanes" target="_blank"><img class="twitter-icon"
  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> |  <a href="https://www.texpresslanes.com/privacy-policy" target="_blank">Privacy Policy</a> │ ©2020 LBJ/NTE/NTE 35W TEXpress Lanes | All Rights Reserved</div>
        </div>
</div>
);

export default IndexPage;
